<template>
  <div class="col-xl-3 col-lg-4 mb-0 userCard">
    <div class="d-flex justify-content-center position-relative">
      <img
        v-if="avatarURL"
        class="avatarImage rounded-circle my-4"
        :src="avatarURL"
        alt="foto del empleado"
      />
      <img
        v-else
        class="avatarImage rounded-circle my-4"
        src="https://storage.assoft.co/img/default/icon_black.svg"
        alt="userPhoto"
      />
      <div
        class="cameraAvatar rounded-circle my-4"
        @click="changeAvatar"
        @mouseenter="showCamera($event.target)"
        @mouseleave="hideCamera($event.target)"
      >
        <svg
          class="bi position-absolute top-50 start-50 translate-middle cameraIcon"
          fill="currentColor"
        >
          <use xlink:href="/img/icons/bootstrap-icons.svg#camera-fill" />
        </svg>
      </div>
      <input
        @change="getFileClickEmployee($event.target.files)"
        type="file"
        name="userProfileAvatarEmployee"
        accept=".png,.jpg,.jpeg,.gif,.webp"
        id="userProfileAvatarEmployee"
        hidden
      />
    </div>
    <strong class="d-flex justify-content-center">{{ userName }}</strong>
    <hr />
    <ul class="nav flex-column bottom-0 start-0 mb-3">
      <li class="nav-item mx-3">
        <router-link
          class="nav-link p-1"
          :to="'/employee/profile/edit/basic/' + $route.params._id"
        >
          <svg class="bi me-2" fill="currentColor">
            <use xlink:href="/img/icons/bootstrap-icons.svg#clipboard-data" />
          </svg>
          Datos Básicos
        </router-link>
      </li>
      <li class="nav-item mx-3">
        <router-link
          class="nav-link p-1"
          :to="'/employee/profile/edit/contact/' + $route.params._id"
        >
          <svg class="bi me-2" fill="currentColor">
            <use xlink:href="/img/icons/bootstrap-icons.svg#info-square" />
          </svg>
          Contacto
        </router-link>
      </li>
      <li class="nav-item mx-3">
        <router-link
          class="nav-link p-1"
          :to="'/employee/profile/edit/origin/' + $route.params._id"
        >
          <svg class="bi me-2" fill="currentColor">
            <use
              xlink:href="/img/icons/bootstrap-icons.svg#arrow-down-right-square"
            />
          </svg>
          Procedencia
        </router-link>
      </li>
      <li class="nav-item mx-3">
        <router-link
          class="nav-link p-1"
          :to="'/employee/profile/edit/emergency/' + $route.params._id"
        >
          <svg class="bi me-2" fill="currentColor">
            <use
              xlink:href="/img/icons/bootstrap-icons.svg#exclamation-square"
            />
          </svg>
          En Caso de Emergencia
        </router-link>
      </li>
      <li class="nav-item mx-3">
        <router-link
          class="nav-link p-1"
          :to="'/employee/profile/edit/labor/' + $route.params._id"
        >
          <svg class="bi me-2" fill="currentColor">
            <use
              xlink:href="/img/icons/bootstrap-icons.svg#person-bounding-box"
            />
          </svg>
          Vinculación Laboral
        </router-link>
      </li>
      <li class="nav-item mx-3">
        <router-link
          class="nav-link p-1"
          :to="'/employee/profile/edit/membership/' + $route.params._id"
        >
          <svg class="bi me-2" fill="currentColor">
            <use xlink:href="/img/icons/bootstrap-icons.svg#shield-check" />
          </svg>
          Seguridad Social
        </router-link>
      </li>
      <li class="nav-item mx-3">
        <router-link
          class="nav-link p-1"
          :to="'/employee/profile/edit/additional/' + $route.params._id"
        >
          <svg class="bi me-2" fill="currentColor">
            <use xlink:href="/img/icons/bootstrap-icons.svg#clipboard-plus" />
          </svg>
          Datos Adicionales
        </router-link>
      </li>
      <!--
      <li class="nav-item mx-3">
        <router-link
          class="nav-link p-1"
          :to="'/employee/profile/edit/roles/' + $route.params._id"
        >
          <svg class="bi me-2" fill="currentColor">
            <use xlink:href="/img/icons/bootstrap-icons.svg#award" />
          </svg>
          Roles
        </router-link>
      </li>
      -->
    </ul>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Storage from "@/modules/storage";
import store from "@/store";

export default {
  name: "UserCard",
  data() {
    return {
      userName: "",
      userId: "",
      avatarURL: false
    };
  },
  created() {
    const store = new Storage();

    store.getData("employee").then(employeeData => {
      employeeData.forEach(employee => {
        if (employee._id === this.$route.params._id) {
          this.userName = employee.name;
          this.userId = employee._id;
          if (employee.avatarURL) {
            this.avatarURL =
              employee.avatarURL +
              "?seed=" +
              Math.floor(Math.random() * 10000000);
          }
        }
      });
    });
  },
  methods: {
    showCamera(div) {
      div.style.opacity = "1";
    },
    hideCamera(div) {
      div.style.opacity = "0";
    },
    changeAvatar() {
      document.getElementById("userProfileAvatarEmployee").click();
    },
    getFileClickEmployee(files) {
      const store = new Storage();
      if (files.length === 0) {
        return true;
      }

      Swal.fire({
        icon: "warning",
        title: "Actualizando foto de perfil",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        stopKeydownPropagation: true
      });

      if (files[0].type.startsWith("image/")) {
        const avatarFile = new FormData();
        const URL =
          new Storage().baseURL + "/optima/employee/" + this.userId + "/upload";
        avatarFile.append("avatar", files[0]);
        console.log(URL);

        axios
          .post(URL, avatarFile, { withCredentials: true })
          .then(res => {
            store.updateData("employee", res.data.body).then(() => {
              location.reload();
            });
          })
          .catch(e => {
            console.log(e);
            Swal.fire({
              icon: "error",
              title: "No es posible realizar esta acción",
              text:
                "Para cambiar la foto de perfil es necesario conexión a Internet"
            });
          });
      } else {
        Swal.fire({
          icon: "error",
          title: "Tipo de archivo no permitido",
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
      }
    }
  }
};
</script>

<style lang="css" scoped>
.userCard {
  background-color: var(--secondary-bg);
  height: fit-content;
}
.userCard a {
  color: var(--main-text);
}
.userCard ul svg {
  height: 16px;
  width: 16px;
}
.router-link-exact-active .danger {
  color: var(--bs-danger) !important;
}
.avatarImage {
  cursor: pointer;
  object-fit: cover;
  width: 100px;
  height: 100px;
}
.cameraAvatar {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.664);
  position: absolute;
  opacity: 0;
  transition: 0.3s ease-in-out;
  width: 100px;
  height: 100px;
}
.cameraIcon {
  color: white;
  width: 50%;
}
</style>
