<template>
  <div class="position-relative">
    <div class="userBanner rounded">
      <div class="pt-3 pb-0 ps-4">
        <strong>Progreso de Información</strong>
      </div>
      <hr />
      <h2 class="text-center fw-bold">{{ progress }} %</h2>
      <div class="progress customProgress mx-5 mt-4">
        <div
          class="progress-bar"
          role="progressbar"
          :aria-valuenow="progress"
          :style="'width: ' + progress + '%'"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import Storage from "@/modules/storage";

export default {
  name: "Profile Progress",
  data() {
    return {
      progress: 0
    };
  },
  mounted() {
    const store = new Storage();
    let count = 0;
    let nullable = 0;
    let total;
    store.getData("employee", this.$route.params._id).then(employeeData => {
      for (const data in employeeData) {
        if (data === "annotations") {
          continue;
        } else if (employeeData[data] === null) {
          nullable++;
        }
        count++;
      }
      total = (nullable * 100) / count;
      total = 100 - total;
      total = Math.floor(total);
      this.progress = total;
    });
  }
};
</script>

<style lang="css" scoped>
.userBanner {
  background-color: var(--secondary-bg);
  height: 210px;
  width: 100%;
}
@media only screen and (max-width: 1400px) {
  .userBanner {
    height: 150px;
  }
  .customProgress {
    margin-top: 0 !important;
  }
}
</style>
