<template>
  <div class="main">
    <div class="row mx-4 my-4">
      <EmployeeCard></EmployeeCard>
      <div class="col-xl-9 col-lg-8 ps-4 userSettings">
        <ProfileProgress></ProfileProgress>
        <div class="mt-4 settingsBody rounded">
          <div class="pt-3 pb-0 ps-4">
            <strong>Contacto</strong>
          </div>
          <hr />
          <div>
            <form class="settingForm" @submit.prevent="sendBasic">
              <div class="mb-3">
                <label for="state" class="form-label"
                  >Departamento de Residencia</label
                >
                <select
                  class="form-select text-capitalize"
                  name="state"
                  v-model="state"
                  required
                >
                  <option
                    v-for="state in stateList"
                    v-bind:value="state._id"
                    :key="state._id"
                    >{{ state.name }}</option
                  >
                </select>
              </div>
              <div class="mb-3">
                <label for="city" class="form-label"
                  >Municipio de Residencia</label
                >
                <select
                  class="form-select text-capitalize"
                  name="city"
                  required
                  v-model="city"
                >
                  <option
                    v-for="city in cityList"
                    v-bind:value="city._id"
                    :key="city._id"
                    >{{ city.name }}</option
                  >
                </select>
              </div>
              <div class="mb-3">
                <label for="address" class="form-label"
                  >Dirección de Residencia</label
                >
                <input
                  type="text"
                  class="form-control"
                  name="address"
                  minlength="1"
                  :maxlength="maxAddress"
                  v-model="address"
                  required
                />
              </div>
              <div class="mb-3">
                <label for="mobile" class="form-label">Número Celular</label>
                <div class="input-group">
                  <span class="input-group-text">+ {{ callingCode }}</span>
                  <input
                    type="number"
                    class="form-control"
                    name="mobile"
                    :min="mobileMin"
                    :max="mobileMax"
                    v-model="mobile"
                    required
                  />
                </div>
              </div>
              <div class="mb-3">
                <label for="phone" class="form-label"
                  >Número Teléfono Fijo</label
                >
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control"
                    name="phone"
                    :min="phoneMin"
                    :max="phoneMax"
                    v-model="phone"
                  />
                </div>
              </div>
              <div class="mb-3">
                <label for="email" class="form-label">Correo Electrónico</label>
                <input
                  type="email"
                  class="form-control"
                  name="email"
                  v-model="email"
                  required
                />
              </div>
              <button type="submit mb-3" class="btn btn-primary shadow-primary">
                Actualizar
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <Legal></Legal>
  </div>
</template>

<script>
import Legal from "@/components/layout/Legal";
import EmployeeCard from "@/views/employee/EmployeeCard";
import ProfileProgress from "@/views/employee/ProfileProgress";

import Storage from "@/modules/storage";
import Swal from "sweetalert2";

export default {
  components: { Legal, EmployeeCard, ProfileProgress },
  name: "Profile Edit Basic",
  data() {
    return {
      state: "",
      stateList: [],
      city: "",
      cityAll: [],
      address: "",
      maxAddress: 1,
      mobile: "",
      mobileMin: 0,
      mobileMax: 1,
      callingCode: "",
      phone: null,
      phoneMin: 0,
      phoneMax: 1,
      email: ""
    };
  },
  methods: {
    sendBasic() {
      Swal.fire({
        icon: "warning",
        title: "Actualizando información",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        stopKeydownPropagation: true,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          setInterval(() => {
            const content = Swal.getContent();
            if (content) {
              const b = content.querySelector("b");
              if (b) {
                b.textContent = Swal.getTimerLeft();
              }
            }
          }, 100);
        }
      });
      const store = new Storage();
      const data = {
        _id: this.$route.params._id,
        state: this.state,
        city: this.city,
        address: this.address,
        mobile: this.mobile,
        phone: this.phone,
        email: this.email
      };
      store
        .updateData("employee", [data], true, "optima")
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Datos Actualizados",
            text: "Todos los datos han sido sincronizados"
          }).then(() => {
            this.$router.go();
          });
        })
        .catch(error => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Error al actualizar los datos",
            text:
              "Los datos han sido guardados en su computador o celular, una vez se restablesca la conexión, los datos se sincronizarán con el servidor"
          }).then(result => {
            this.$router.go();
          });
        });
    }
  },
  computed: {
    cityList: function() {
      const data = [];
      this.cityAll.forEach(city => {
        if (city.state === this.state) {
          data.push(city);
        }
      });
      return data;
    }
  },
  beforeCreate() {
    const store = new Storage();

    store.getData("employee", this.$route.params._id).then(employeeData => {
      store.getData("branch", employeeData.branch).then(branchData => {
        store.getData("city", branchData.city).then(cityData => {
          localStorage.setItem(
            "currentLocation",
            "Empleados / Perfiles / " +
              cityData.name +
              " / " +
              employeeData.name +
              " / Contacto"
          );
        });
      });
    });
  },
  mounted() {
    const store = new Storage();
    store.getData("city").then(cityData => {
      cityData.sort(function(a, b) {
        return a.name.localeCompare(b.name);
      });
      cityData.forEach(city => {
        this.cityAll.push(city);
      });
    });
    store.getData("state").then(stateData => {
      stateData.sort(function(a, b) {
        return a.name.localeCompare(b.name);
      });
      this.stateList = stateData;
    });
    store.getData("country").then(countryData => {
      this.callingCode = countryData[0].callingCode;
    });
    store.getData("employee", this.$route.params._id).then(employee => {
      this.state = employee.state;
      this.city = employee.city;
      this.address = employee.address;
      this.mobile = employee.mobile;
      this.phone = employee.phone;
      this.email = employee.email;
    });
    store.getData("schema").then(schemaList => {
      schemaList.forEach(schema => {
        if (schema.store === "employee") {
          this.maxAddress = schema.data.address.max;
          this.mobileMin = schema.data.mobile.min;
          this.mobileMax = schema.data.mobile.max;
          this.phoneMin = schema.data.phone.min;
          this.phoneMax = schema.data.phone.max;
        }
      });
    });
  }
};
</script>

<style lang="css" scoped>
.settingsBody {
  background-color: var(--secondary-bg);
}
.settingForm {
  margin: auto;
  padding-bottom: 20px;
  width: 50%;
  min-width: 280px;
}
@media only screen and (max-width: 990px) {
  .userSettings {
    padding: 0 !important;
  }
  .userSettings {
    margin-block: 30px;
  }
}
</style>
